import axiosInstance from "auth/FetchInterceptor";

import { DANHMUC_URL, LUUVET_URL, ACCOUNT_LOGIN } from "constants/ApiConstant";

const DanhMucService = {};

/// --> DmNhanVien
DanhMucService.GetAllDmNhanVien = async (data) => {
  return await axiosInstance.post(`${DANHMUC_URL}/get-all-dmnhanvien`, data);
};
DanhMucService.UpsertDmNguoiDung = async (data) => {
  return await axiosInstance.put(`${DANHMUC_URL}/upsert-dmnhanvien`, data);
};
DanhMucService.GetDmNguoiDung = async (data) => {
  return await axiosInstance.get(`${DANHMUC_URL}/get-dmnhanvien?Id=${data}`);
};
DanhMucService.GetProfile = async () => {
  return await axiosInstance.get(`${ACCOUNT_LOGIN}/get-profile`);
};

/// --> DmTinhThanh
DanhMucService.GetAllDmTinhThanh = async (data) => {
  return await axiosInstance.post(`${DANHMUC_URL}/get-all-dmtinhthanh`, data);
};
DanhMucService.UpsertDmTinhThanh = async (data) => {
  return await axiosInstance.post(`${DANHMUC_URL}/upsert-dmtinhthanh`, data);
};

///->> CauHinhDanhMuc
DanhMucService.GetAllCauHinhDanhMuc = async (data) => {
  return await axiosInstance.post(
    `${DANHMUC_URL}/get-all-cauhinhdanhmuc`,
    data
  );
};
DanhMucService.UpsertCauHinhDanhMuc = async (data) => {
  return await axiosInstance.put(`${DANHMUC_URL}/upsert-cauhinhdanhmuc`, data);
};
DanhMucService.GetAllEnumDanhMuc = async (data) => {
  return await axiosInstance.post(`${DANHMUC_URL}/get-all-enum-danhmuc`, data);
};

///->> GetAllDmHangHoa
DanhMucService.GetAllDmHangHoa = async (data) => {
  return await axiosInstance.post(`${DANHMUC_URL}/get-all-dmhanghoa`, data);
};
DanhMucService.UpsertDmHangHoa = async (data) => {
  return await axiosInstance.put(`${DANHMUC_URL}/upsert-dmhanghoa`, data);
};

///->> GetAllDmKhachHang
DanhMucService.GetAllDmKhachHang = async (data) => {
  return await axiosInstance.post(`${DANHMUC_URL}/get-all-dmkhachhang`, data);
};
DanhMucService.GetAllDmKhachHangThu = async (data) => {
  return await axiosInstance.post(
    `${DANHMUC_URL}/get-all-dmkhachhang-thu`,
    data
  );
};
DanhMucService.UpsertDmKhachHang = async (data) => {
  return await axiosInstance.put(`${DANHMUC_URL}/upsert-dmkhachhang`, data);
};

DanhMucService.GetAllLuuVet = async (data) => {
  return await axiosInstance.post(
    `${LUUVET_URL}/get-all-luuvetnguoidung`,
    data
  );
};
export default DanhMucService;
