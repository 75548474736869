import { AUTH_TOKEN, DONVI_ID } from "constants/AuthConstant";

export const setAuthenData = (data) => {
  const token = data.tokenData.token;
  localStorage.setItem(AUTH_TOKEN, token);

  const donViId_ = data.donViId;
  localStorage.setItem(DONVI_ID, donViId_);

  // const refreshToken = data.tokenData.refreshToken;
  // localStorage.setItem(REFRESH_TOKEN, refreshToken);
  // const expiredTime = data.tokenData.expires;
  // localStorage.setItem(EXPIRE_TIME, expiredTime);
  // const firebaseToken = data.firebaseToken;
  // localStorage.setItem(FIREBASE_TOKEN, firebaseToken);
  //localStorage.setItem(USER_ROLES, data.userRoles);
};
