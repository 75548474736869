export const BASE_URL = "http://localhost:3000";
export const API_BASE_URL = "http://localhost:5148";

export const ACCOUNT_LOGIN = `${API_BASE_URL}/auth`;
export const DANHMUC_URL = `${API_BASE_URL}/danh-muc`;
export const PHIEUNHAP_URL = `${API_BASE_URL}/phieu-nhap`;
export const PHIEUXUAT_URL = `${API_BASE_URL}/phieu-xuat`;
export const THUCHI_URL = `${API_BASE_URL}/thu-chi`;
export const BAOCAO_URL = `${API_BASE_URL}/bao-cao-thong-ke`;
export const LUUVET_URL = `${API_BASE_URL}/luu-vet`;
export const UPLOAD_URL = `${API_BASE_URL}/media`;
