import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import DanhMucService from "services/DanhMucService";

/// --> DmNhanVien
export const GetAllDmNhanVien = createAsyncThunk(
  "DanhMuc/GetAllDmNhanVien",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await DanhMucService.GetAllDmNhanVien(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const UpsertDmNguoiDung = createAsyncThunk(
  "DanhMuc/UpsertDmNguoiDung",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await DanhMucService.UpsertDmNguoiDung(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const GetDmNguoiDung = createAsyncThunk(
  "DanhMuc/GetDmNguoiDung",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await DanhMucService.GetDmNguoiDung(payload.id);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
/// --> End DmTinhThanh

/// --> DmTinhThanh
export const GetAllDmTinhThanh = createAsyncThunk(
  "DanhMuc/GetAllDmTinhThanh",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await DanhMucService.GetAllDmTinhThanh(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const UpsertDmTinhThanh = createAsyncThunk(
  "DanhMuc/UpsertDmTinhThanh",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await DanhMucService.UpsertDmTinhThanh(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
/// --> End DmTinhThanh

/// --> CauHinhDanhMuc
export const GetAllCauHinhDanhMuc_DVT = createAsyncThunk(
  "DanhMuc/GetAllCauHinhDanhMuc_DVT",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await DanhMucService.GetAllCauHinhDanhMuc(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const GetAllCauHinhDanhMuc_Kho = createAsyncThunk(
  "DanhMuc/GetAllCauHinhDanhMuc_Kho",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await DanhMucService.GetAllCauHinhDanhMuc(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const GetAllCauHinhDanhMuc_HinhThucTT = createAsyncThunk(
  "DanhMuc/GetAllCauHinhDanhMuc_HinhThucTT",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await DanhMucService.GetAllCauHinhDanhMuc(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const GetAllCauHinhDanhMuc_LoaiKhachHang = createAsyncThunk(
  "DanhMuc/GetAllCauHinhDanhMuc_LoaiKhachHang",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await DanhMucService.GetAllCauHinhDanhMuc(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const GetAllCauHinhDanhMuc_NhomHangHoa = createAsyncThunk(
  "DanhMuc/GetAllCauHinhDanhMuc_NhomHangHoa",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await DanhMucService.GetAllCauHinhDanhMuc(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const UpsertCauHinhDanhMuc = createAsyncThunk(
  "DanhMuc/UpsertCauHinhDanhMuc",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await DanhMucService.UpsertCauHinhDanhMuc(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const GetAllEnumDanhMuc_LyDoNhap = createAsyncThunk(
  "DanhMuc/GetAllEnumDanhMuc_LyDoNhap",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await DanhMucService.GetAllEnumDanhMuc(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const GetAllEnumDanhMuc_LyDoXuat = createAsyncThunk(
  "DanhMuc/GetAllEnumDanhMuc_LyDoXuat",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await DanhMucService.GetAllEnumDanhMuc(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const GetAllEnumDanhMuc_LyDoThu = createAsyncThunk(
  "DanhMuc/GetAllEnumDanhMuc_LyDoThu",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await DanhMucService.GetAllEnumDanhMuc(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const GetAllEnumDanhMuc_LyDoChi = createAsyncThunk(
  "DanhMuc/GetAllEnumDanhMuc_LyDoChi",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await DanhMucService.GetAllEnumDanhMuc(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const GetAllEnumDanhMuc_HinhThucTt = createAsyncThunk(
  "DanhMuc/GetAllEnumDanhMuc_HinhThucTt",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await DanhMucService.GetAllEnumDanhMuc(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
/// --> End CauHinhDanhMuc

/// --> DmHangHoa
export const GetAllDmHangHoa = createAsyncThunk(
  "DanhMuc/GetAllDmHangHoa",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await DanhMucService.GetAllDmHangHoa(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const UpsertDmHangHoa = createAsyncThunk(
  "DanhMuc/UpsertDmHangHoa",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await DanhMucService.UpsertDmHangHoa(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

/// --> DmKhachHang
export const GetAllDmKhachHangLe = createAsyncThunk(
  "DanhMuc/GetAllDmKhachHangLe",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await DanhMucService.GetAllDmKhachHang(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const GetAllDmKhachHangSy = createAsyncThunk(
  "DanhMuc/GetAllDmKhachHangSy",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await DanhMucService.GetAllDmKhachHang(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const GetAllDmKhachHangNcc = createAsyncThunk(
  "DanhMuc/GetAllDmKhachHangNcc",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await DanhMucService.GetAllDmKhachHang(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const GetAllDmKhachHangThu = createAsyncThunk(
  "DanhMuc/GetAllDmKhachHangThu",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await DanhMucService.GetAllDmKhachHangThu(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const UpsertDmKhachHang = createAsyncThunk(
  "DanhMuc/UpsertDmKhachHang",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await DanhMucService.UpsertDmKhachHang(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const GetAllLuuVet = createAsyncThunk(
  "DanhMuc/GetAllLuuVet",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await DanhMucService.GetAllLuuVet(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const GetProfile = createAsyncThunk(
  "DanhMuc/GetProfile",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await DanhMucService.GetProfile();
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  error: false,
  profile: {},
  DmTinhThanhList: [],
  DmDonViTinhList: [],
  DmKhoHangList: [],
  DmHinhThucThanhToanList: [],
  DmLoaiKhachHangList: [],
  DmNhomHangHoaList: [],
  DmHangHoaList: [],
  DmKhachHangLeList: [],
  DmKhachHangSyList: [],
  DmKhachHangNccList: [],
  DmNhanVienList: [],
  NhanVienDetail: {},
  DmLyDoNhapList: [],
  DmLyDoXuatList: [],
  DmLyDoThuList: [],
  DmLyDoChiList: [],
  DmHinhThucTtList: [],
  DmKhachHangThuList: [],
  LuuVetList: [],
};

export const DanhMucSlice = createSlice({
  name: "DanhMuc",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
  },
  extraReducers: (builder) => {
    builder
      /// --> GetProfile
      .addCase(GetProfile.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(GetProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.profile = action.payload;
        state.error = false;
      })
      .addCase(GetProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })
      /// --> GetAllLuuVet
      .addCase(GetAllLuuVet.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(GetAllLuuVet.fulfilled, (state, action) => {
        state.loading = false;
        state.LuuVetList = action.payload;
        state.error = false;
      })
      .addCase(GetAllLuuVet.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })
      /// --> GetAllEnumDanhMuc_HinhThucTt
      .addCase(GetAllEnumDanhMuc_HinhThucTt.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(GetAllEnumDanhMuc_HinhThucTt.fulfilled, (state, action) => {
        state.loading = false;
        state.DmHinhThucTtList = action.payload;
        state.error = false;
      })
      .addCase(GetAllEnumDanhMuc_HinhThucTt.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })
      /// --> GetAllEnumDanhMuc_LyDoChi
      .addCase(GetAllEnumDanhMuc_LyDoChi.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(GetAllEnumDanhMuc_LyDoChi.fulfilled, (state, action) => {
        state.loading = false;
        state.DmLyDoChiList = action.payload;
        state.error = false;
      })
      .addCase(GetAllEnumDanhMuc_LyDoChi.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })
      /// --> GetAllEnumDanhMuc_LyDoThu
      .addCase(GetAllEnumDanhMuc_LyDoThu.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(GetAllEnumDanhMuc_LyDoThu.fulfilled, (state, action) => {
        state.loading = false;
        state.DmLyDoThuList = action.payload;
        state.error = false;
      })
      .addCase(GetAllEnumDanhMuc_LyDoThu.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })
      /// --> GetAllEnumDanhMuc_LyDoXuat
      .addCase(GetAllEnumDanhMuc_LyDoXuat.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(GetAllEnumDanhMuc_LyDoXuat.fulfilled, (state, action) => {
        state.loading = false;
        state.DmLyDoXuatList = action.payload;
        state.error = false;
      })
      .addCase(GetAllEnumDanhMuc_LyDoXuat.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })
      /// --> GetAllEnumDanhMuc_LyDoNhap
      .addCase(GetAllEnumDanhMuc_LyDoNhap.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(GetAllEnumDanhMuc_LyDoNhap.fulfilled, (state, action) => {
        state.loading = false;
        state.DmLyDoNhapList = action.payload;
        state.error = false;
      })
      .addCase(GetAllEnumDanhMuc_LyDoNhap.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })
      /// --> GetAllDmKhachHangThu
      .addCase(GetAllDmKhachHangThu.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(GetAllDmKhachHangThu.fulfilled, (state, action) => {
        state.loading = false;
        state.DmKhachHangThuList = action.payload;
        state.error = false;
      })
      .addCase(GetAllDmKhachHangThu.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })
      /// --> GetAllDmKhachHangNcc
      .addCase(GetAllDmKhachHangNcc.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(GetAllDmKhachHangNcc.fulfilled, (state, action) => {
        state.loading = false;
        state.DmKhachHangNccList = action.payload;
        state.error = false;
      })
      .addCase(GetAllDmKhachHangNcc.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })
      /// --> GetAllDmKhachHangSy
      .addCase(GetAllDmKhachHangSy.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(GetAllDmKhachHangSy.fulfilled, (state, action) => {
        state.loading = false;
        state.DmKhachHangSyList = action.payload;
        state.error = false;
      })
      .addCase(GetAllDmKhachHangSy.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })
      /// --> GetAllDmKhachHangLe
      .addCase(GetAllDmKhachHangLe.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(GetAllDmKhachHangLe.fulfilled, (state, action) => {
        state.loading = false;
        state.DmKhachHangLeList = action.payload;
        state.error = false;
      })
      .addCase(GetAllDmKhachHangLe.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })
      /// --> GetAllDmHangHoa
      .addCase(GetAllDmHangHoa.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(GetAllDmHangHoa.fulfilled, (state, action) => {
        state.loading = false;
        state.DmHangHoaList = action.payload;
        state.error = false;
      })
      .addCase(GetAllDmHangHoa.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })
      /// --> GetAllCauHinhDanhMuc_NhomHangHoa
      .addCase(GetAllCauHinhDanhMuc_NhomHangHoa.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(GetAllCauHinhDanhMuc_NhomHangHoa.fulfilled, (state, action) => {
        state.loading = false;
        state.DmNhomHangHoaList = action.payload;
        state.error = false;
      })
      .addCase(GetAllCauHinhDanhMuc_NhomHangHoa.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })
      /// --> GetAllCauHinhDanhMuc_LoaiKhachHang
      .addCase(GetAllCauHinhDanhMuc_LoaiKhachHang.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(
        GetAllCauHinhDanhMuc_LoaiKhachHang.fulfilled,
        (state, action) => {
          state.loading = false;
          state.DmLoaiKhachHangList = action.payload;
          state.error = false;
        }
      )
      .addCase(GetAllCauHinhDanhMuc_LoaiKhachHang.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })
      /// --> GetAllCauHinhDanhMuc_HinhThucTT
      .addCase(GetAllCauHinhDanhMuc_HinhThucTT.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(GetAllCauHinhDanhMuc_HinhThucTT.fulfilled, (state, action) => {
        state.loading = false;
        state.DmHinhThucThanhToanList = action.payload;
        state.error = false;
      })
      .addCase(GetAllCauHinhDanhMuc_HinhThucTT.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })
      /// --> GetAllCauHinhDanhMuc_Kho
      .addCase(GetAllCauHinhDanhMuc_Kho.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(GetAllCauHinhDanhMuc_Kho.fulfilled, (state, action) => {
        state.loading = false;
        state.DmKhoHangList = action.payload;
        state.error = false;
      })
      .addCase(GetAllCauHinhDanhMuc_Kho.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })
      /// --> GetAllCauHinhDanhMuc_DVT
      .addCase(GetAllCauHinhDanhMuc_DVT.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(GetAllCauHinhDanhMuc_DVT.fulfilled, (state, action) => {
        state.loading = false;
        state.DmDonViTinhList = action.payload;
        state.error = false;
      })
      .addCase(GetAllCauHinhDanhMuc_DVT.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })
      /// --> UpsertDmNguoiDung
      .addCase(UpsertDmNguoiDung.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(UpsertDmNguoiDung.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
      })
      .addCase(UpsertDmNguoiDung.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })
      /// --> GetDmNguoiDung
      .addCase(GetDmNguoiDung.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(GetDmNguoiDung.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.NhanVienDetail = action.payload;
      })
      .addCase(GetDmNguoiDung.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })
      /// --> GetAllDmNhanVien
      .addCase(GetAllDmNhanVien.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(GetAllDmNhanVien.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.DmNhanVienList = action.payload;
      })
      .addCase(GetAllDmNhanVien.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })
      /// --> GetAllDmTinhThanh
      .addCase(GetAllDmTinhThanh.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(GetAllDmTinhThanh.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.DmTinhThanhList = action.payload;
      })
      .addCase(GetAllDmTinhThanh.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })
      /// --> UpsertDmTinhThanh
      .addCase(UpsertDmTinhThanh.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(UpsertDmTinhThanh.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
      })
      .addCase(UpsertDmTinhThanh.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const { showLoading } = DanhMucSlice.actions;

export default DanhMucSlice.reducer;
