import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import BaoCaoThongKeService from "services/BaoCaoThongKeService";

/// --> BaoCaoThongKe
export const GetBaoCaoTonKho = createAsyncThunk(
  "BaoCaoThongKe/GetBaoCaoTonKho ",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await BaoCaoThongKeService.GetBaoCaoTonKho(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const GetBaoCaoCongNo = createAsyncThunk(
  "BaoCaoThongKe/GetBaoCaoCongNo ",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await BaoCaoThongKeService.GetBaoCaoCongNo(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const GetBaoCaoDoanhThu = createAsyncThunk(
  "BaoCaoThongKe/GetBaoCaoDoanhThu ",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await BaoCaoThongKeService.GetBaoCaoDoanhThu(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const GetBaoCaoThuChi = createAsyncThunk(
  "BaoCaoThongKe/GetBaoCaoThuChi ",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await BaoCaoThongKeService.GetBaoCaoThuChi(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const GetBaoCaoSoNhapXuat = createAsyncThunk(
  "BaoCaoThongKe/GetBaoCaoSoNhapXuat ",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await BaoCaoThongKeService.GetBaoCaoSoNhapXuat(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const GetDashboardDoanhThu = createAsyncThunk(
  "BaoCaoThongKe/GetDashboardDoanhThu ",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await BaoCaoThongKeService.GetDashboardDoanhThu(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const GetDashboardTongHop = createAsyncThunk(
  "BaoCaoThongKe/GetDashboardTongHop ",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await BaoCaoThongKeService.GetDashboardTongHop(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const GetDashboardTopBanChay = createAsyncThunk(
  "BaoCaoThongKe/GetDashboardTopBanChay ",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await BaoCaoThongKeService.GetDashboardTopBanChay(
        payload
      );
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const GetDashboardDonHangMoi = createAsyncThunk(
  "BaoCaoThongKe/GetDashboardDonHangMoi ",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await BaoCaoThongKeService.GetDashboardDonHangMoi(
        payload
      );
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  error: false,
  BaoCaoTonKhoList: [],
  BaoCaoCongNoList: [],
  BaoCaoThuChiList: [],
  BaoCaoDoanhThuList: [],
  BaoCaoSoNhapXuatList: [],
  DashboardDoanhThuDetail: {},
  DashboardTongHopDetail: {},
  DashboardTopBanChayList: [],
  DashboardDonHangMoiList: [],
};

export const BaoCaoThongKeSlice = createSlice({
  name: "BaoCaoThongKeSlice",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
  },
  extraReducers: (builder) => {
    builder
      /// --> GetDashboardDonHangMoi
      .addCase(GetDashboardDonHangMoi.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(GetDashboardDonHangMoi.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.DashboardDonHangMoiList = action.payload;
      })
      .addCase(GetDashboardDonHangMoi.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })
      /// --> GetDashboardTopBanChay
      .addCase(GetDashboardTopBanChay.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(GetDashboardTopBanChay.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.DashboardTopBanChayList = action.payload;
      })
      .addCase(GetDashboardTopBanChay.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })
      /// --> GetDashboardTongHop
      .addCase(GetDashboardTongHop.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(GetDashboardTongHop.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.DashboardTongHopDetail = action.payload;
      })
      .addCase(GetDashboardTongHop.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })
      /// --> GetDashboardDoanhThu
      .addCase(GetDashboardDoanhThu.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(GetDashboardDoanhThu.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.DashboardDoanhThuDetail = action.payload;
      })
      .addCase(GetDashboardDoanhThu.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })
      /// --> GetBaoCaoSoNhapXuat
      .addCase(GetBaoCaoSoNhapXuat.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(GetBaoCaoSoNhapXuat.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.BaoCaoSoNhapXuatList = action.payload;
      })
      .addCase(GetBaoCaoSoNhapXuat.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })
      /// --> GetBaoCaoThuChi
      .addCase(GetBaoCaoThuChi.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(GetBaoCaoThuChi.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.BaoCaoThuChiList = action.payload;
      })
      .addCase(GetBaoCaoThuChi.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })
      /// --> GetBaoCaoDoanhThu
      .addCase(GetBaoCaoDoanhThu.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(GetBaoCaoDoanhThu.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.BaoCaoDoanhThuList = action.payload;
      })
      .addCase(GetBaoCaoDoanhThu.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })
      /// --> GetBaoCaoCongNo
      .addCase(GetBaoCaoCongNo.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(GetBaoCaoCongNo.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.BaoCaoCongNoList = action.payload;
      })
      .addCase(GetBaoCaoCongNo.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })
      /// --> GetBaoCaoTonKho
      .addCase(GetBaoCaoTonKho.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(GetBaoCaoTonKho.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.BaoCaoTonKhoList = action.payload;
      })
      .addCase(GetBaoCaoTonKho.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const { showLoading } = BaoCaoThongKeSlice.actions;

export default BaoCaoThongKeSlice.reducer;
