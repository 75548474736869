import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import PhieuNhapKhoService from "services/PhieuNhapKhoService";

/// --> PhieuNhapKho
export const GetAllPhieuNhapKho = createAsyncThunk(
  "PhieuNhapKho/GetAllPhieuNhapKho",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await PhieuNhapKhoService.GetAllPhieuNhapKho(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const UpsertPhieuNhapKho = createAsyncThunk(
  "PhieuNhapKho/UpsertPhieuNhapKho",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await PhieuNhapKhoService.UpsertPhieuNhapKho(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const GetPhieuNhapKho = createAsyncThunk(
  "PhieuNhapKho/GetPhieuNhapKho",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await PhieuNhapKhoService.GetPhieuNhapKho(payload.id);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const DuyetPhieuNhapKho = createAsyncThunk(
  "PhieuNhapKho/DuyetPhieuNhapKho",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await PhieuNhapKhoService.DuyetPhieuNhapKho(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const XoaPhieuNhapKho = createAsyncThunk(
  "PhieuNhapKho/XoaPhieuNhapKho",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await PhieuNhapKhoService.XoaPhieuNhapKho(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
/// --> Chi tiết
export const GetPhieuNhapKhoChiTiet = createAsyncThunk(
  "PhieuNhapKho/GetPhieuNhapKhoChiTiet",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await PhieuNhapKhoService.GetPhieuNhapKhoChiTiet(
        payload.id
      );
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const UpsertPhieuNhapKhoChiTiet = createAsyncThunk(
  "PhieuNhapKho/UpsertPhieuNhapKhoChiTiet",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await PhieuNhapKhoService.UpsertPhieuNhapKhoChiTiet(
        payload
      );
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  error: false,
  PhieuNhapKhoList: [],
  PhieuNhapKhoDetail: {},
  PhieuNhapKhoChiTietList: [],
};

export const PhieuNhapKhoSlice = createSlice({
  name: "phieuNhapKhoSlice",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
  },
  extraReducers: (builder) => {
    builder
      /// --> GetPhieuNhapKhoChiTiet
      .addCase(GetPhieuNhapKhoChiTiet.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(GetPhieuNhapKhoChiTiet.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.PhieuNhapKhoChiTietList = action.payload;
      })
      .addCase(GetPhieuNhapKhoChiTiet.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })
      /// --> GetPhieuNhapKho
      .addCase(GetPhieuNhapKho.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(GetPhieuNhapKho.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.PhieuNhapKhoDetail = action.payload;
      })
      .addCase(GetPhieuNhapKho.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })
      /// --> GetAllPhieuNhapKho
      .addCase(GetAllPhieuNhapKho.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(GetAllPhieuNhapKho.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.PhieuNhapKhoList = action.payload;
      })
      .addCase(GetAllPhieuNhapKho.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const { showLoading } = PhieuNhapKhoSlice.actions;

export default PhieuNhapKhoSlice.reducer;
