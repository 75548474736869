import axiosInstance from "auth/FetchInterceptor";

import { PHIEUNHAP_URL } from "constants/ApiConstant";

const PhieuNhapKhoService = {};

/// --> Cuống
PhieuNhapKhoService.GetAllPhieuNhapKho = async (data) => {
  return await axiosInstance.post(
    `${PHIEUNHAP_URL}/get-all-phieunhaphang`,
    data
  );
};

PhieuNhapKhoService.UpsertPhieuNhapKho = async (data) => {
  return await axiosInstance.put(`${PHIEUNHAP_URL}/upsert-phieunhaphang`, data);
};

PhieuNhapKhoService.GetPhieuNhapKho = async (data) => {
  return await axiosInstance.get(
    `${PHIEUNHAP_URL}/get-phieunhaphang?Id=${data}`
  );
};
PhieuNhapKhoService.DuyetPhieuNhapKho = async (data) => {
  return await axiosInstance.post(`${PHIEUNHAP_URL}/duyet-phieunhaphang`, data);
};
PhieuNhapKhoService.XoaPhieuNhapKho = async (data) => {
  return await axiosInstance.post(`${PHIEUNHAP_URL}/xoa-phieunhaphang`, data);
};
///-> Chi tiêt
PhieuNhapKhoService.UpsertPhieuNhapKhoChiTiet = async (data) => {
  return await axiosInstance.put(
    `${PHIEUNHAP_URL}/upsert-phieunhaphang-chitiet`,
    data
  );
};

PhieuNhapKhoService.GetPhieuNhapKhoChiTiet = async (data) => {
  return await axiosInstance.get(
    `${PHIEUNHAP_URL}/get-phieunhaphang-chitiet?Id=${data}`
  );
};

export default PhieuNhapKhoService;
