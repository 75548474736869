import { combineReducers } from "redux";
import theme from "./slices/themeSlice";
import auth from "./slices/authSlice";

import danhMuc from "./slices/danhMucSlice";
import phieuNhapKho from "./slices/phieuNhapKhoSlice";
import phieuXuatKho from "./slices/phieuXuatKhoSlice";
import phieuThuChi from "./slices/phieuThuChiSlice";
import baoCaoThongKe from "./slices/baoCaoThongKeSlice";

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    auth,
    danhMuc,
    phieuNhapKho,
    phieuXuatKho,
    phieuThuChi,
    baoCaoThongKe,
    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

export default rootReducer;
