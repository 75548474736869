import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import PhieuXuatKhoService from "services/PhieuXuatKhoService";

/// --> PhieuXuatKho
export const GetAllPhieuXuatKho = createAsyncThunk(
  "PhieuXuatKho/GetAllPhieuXuatKho",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await PhieuXuatKhoService.GetAllPhieuXuatKho(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const UpsertPhieuXuatKho = createAsyncThunk(
  "PhieuXuatKho/UpsertPhieuXuatKho",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await PhieuXuatKhoService.UpsertPhieuXuatKho(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const GetPhieuXuatKho = createAsyncThunk(
  "PhieuXuatKho/GetPhieuXuatKho",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await PhieuXuatKhoService.GetPhieuXuatKho(payload.id);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const DuyetPhieuXuatKho = createAsyncThunk(
  "PhieuNhapKho/DuyetPhieuXuatKho",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await PhieuXuatKhoService.DuyetPhieuXuatKho(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const XoaPhieuXuatKho = createAsyncThunk(
  "PhieuNhapKho/XoaPhieuXuatKho",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await PhieuXuatKhoService.XoaPhieuXuatKho(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
/// --> Chi tiết
export const GetPhieuXuatKhoChiTiet = createAsyncThunk(
  "PhieuXuatKho/GetPhieuXuatKhoChiTiet",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await PhieuXuatKhoService.GetPhieuXuatKhoChiTiet(
        payload.id
      );
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const UpsertPhieuXuatKhoChiTiet = createAsyncThunk(
  "PhieuXuatKho/UpsertPhieuXuatKhoChiTiet",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await PhieuXuatKhoService.UpsertPhieuXuatKhoChiTiet(
        payload
      );
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const GetTonHangHoa = createAsyncThunk(
  "PhieuXuatKho/GetTonHangHoa",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await PhieuXuatKhoService.GetTonHangHoa(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  error: false,
  PhieuXuatKhoList: [],
  PhieuXuatKhoDetail: {},
  PhieuXuatKhoChiTietList: [],
  TonHangHoaList: [],
};

export const PhieuXuatKhoSlice = createSlice({
  name: "phieuXuatKhoSlice",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
  },
  extraReducers: (builder) => {
    builder
      /// --> TonHangHoaList
      .addCase(GetTonHangHoa.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(GetTonHangHoa.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.TonHangHoaList = action.payload;
      })
      .addCase(GetTonHangHoa.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })
      /// --> GetPhieuXuatKhoChiTiet
      .addCase(GetPhieuXuatKhoChiTiet.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(GetPhieuXuatKhoChiTiet.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.PhieuXuatKhoChiTietList = action.payload;
      })
      .addCase(GetPhieuXuatKhoChiTiet.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })
      /// --> GetPhieuXuatKho
      .addCase(GetPhieuXuatKho.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(GetPhieuXuatKho.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.PhieuXuatKhoDetail = action.payload;
      })
      .addCase(GetPhieuXuatKho.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })
      /// --> GetAllPhieuXuatKho
      .addCase(GetAllPhieuXuatKho.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(GetAllPhieuXuatKho.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.PhieuXuatKhoList = action.payload;
      })
      .addCase(GetAllPhieuXuatKho.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const { showLoading } = PhieuXuatKhoSlice.actions;

export default PhieuXuatKhoSlice.reducer;
