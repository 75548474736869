import axiosInstance from "auth/FetchInterceptor";

import { ACCOUNT_LOGIN } from "constants/ApiConstant";

const AuthService = {};

AuthService.login = async (data) => {
  return await axiosInstance.post(`${ACCOUNT_LOGIN}/login`, data);
};

AuthService.register = async (data) => {
  return await axiosInstance.post(`${ACCOUNT_LOGIN}/register`, data);
};

export default AuthService;
