import axiosInstance from "auth/FetchInterceptor";

import { THUCHI_URL } from "constants/ApiConstant";

const PhieuThuChiService = {};

/// --> Cuống
PhieuThuChiService.GetAllPhieuThuChi = async (data) => {
  return await axiosInstance.post(`${THUCHI_URL}/get-all-phieuthuchi`, data);
};

PhieuThuChiService.UpsertPhieuThuChi = async (data) => {
  return await axiosInstance.put(`${THUCHI_URL}/upsert-phieuthuchi`, data);
};

PhieuThuChiService.GetPhieuThuChi = async (data) => {
  return await axiosInstance.get(`${THUCHI_URL}/get-phieuthuchi?Id=${data}`);
};

PhieuThuChiService.DuyetPhieuThuChi = async (data) => {
  return await axiosInstance.post(`${THUCHI_URL}/duyet-phieuthuchi`, data);
};

PhieuThuChiService.XoaPhieuThuChi = async (data) => {
  return await axiosInstance.post(`${THUCHI_URL}/xoa-phieuthuchi`, data);
};
export default PhieuThuChiService;
