import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import PhieuThuChiService from "services/PhieuThuChiService";

/// --> PhieuThuChi
export const GetAllPhieuThu = createAsyncThunk(
  "PhieuThuChi/GetAllPhieuThu ",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await PhieuThuChiService.GetAllPhieuThuChi(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const GetAllPhieuChi = createAsyncThunk(
  "PhieuThuChi/GetAllPhieuChi ",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await PhieuThuChiService.GetAllPhieuThuChi(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const UpsertPhieuThuChi = createAsyncThunk(
  "PhieuThuChi/UpsertPhieuThuChi",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await PhieuThuChiService.UpsertPhieuThuChi(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const GetPhieuThuChi = createAsyncThunk(
  "PhieuThuChi/GetPhieuThuChi",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await PhieuThuChiService.GetPhieuThuChi(payload.id);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const DuyetPhieuThuChi = createAsyncThunk(
  "PhieuThuChi/DuyetPhieuThuChi",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await PhieuThuChiService.DuyetPhieuThuChi(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const XoaPhieuThuChi = createAsyncThunk(
  "PhieuThuChi/XoaPhieuThuChi",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await PhieuThuChiService.XoaPhieuThuChi(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  error: false,
  PhieuThuList: [],
  PhieuThuDetail: {},
  PhieuChiList: [],
  PhieuChiDetail: {},
};

export const PhieuThuChiSlice = createSlice({
  name: "PhieuThuChiSlice",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
  },
  extraReducers: (builder) => {
    builder
      /// --> GetPhieuThuChi
      .addCase(GetPhieuThuChi.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(GetPhieuThuChi.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.PhieuThuChiDetail = action.payload;
      })
      .addCase(GetPhieuThuChi.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })
      /// --> GetAllPhieuChi
      .addCase(GetAllPhieuChi.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(GetAllPhieuChi.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.PhieuChiList = action.payload;
      })
      .addCase(GetAllPhieuChi.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })
      /// --> GetAllPhieuThu
      .addCase(GetAllPhieuThu.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(GetAllPhieuThu.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.PhieuThuList = action.payload;
      })
      .addCase(GetAllPhieuThu.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const { showLoading } = PhieuThuChiSlice.actions;

export default PhieuThuChiSlice.reducer;
