import axiosInstance from "auth/FetchInterceptor";

import { PHIEUXUAT_URL } from "constants/ApiConstant";

const PhieuXuatKhoService = {};

/// --> Cuống
PhieuXuatKhoService.GetAllPhieuXuatKho = async (data) => {
  return await axiosInstance.post(
    `${PHIEUXUAT_URL}/get-all-phieuxuathang`,
    data
  );
};

PhieuXuatKhoService.UpsertPhieuXuatKho = async (data) => {
  return await axiosInstance.put(`${PHIEUXUAT_URL}/upsert-phieuxuathang`, data);
};

PhieuXuatKhoService.GetPhieuXuatKho = async (data) => {
  return await axiosInstance.get(
    `${PHIEUXUAT_URL}/get-phieuxuathang?Id=${data}`
  );
};
PhieuXuatKhoService.DuyetPhieuXuatKho = async (data) => {
  return await axiosInstance.post(`${PHIEUXUAT_URL}/duyet-phieuxuathang`, data);
};
PhieuXuatKhoService.XoaPhieuXuatKho = async (data) => {
  return await axiosInstance.post(`${PHIEUXUAT_URL}/xoa-phieuxuathang`, data);
};
///-> Chi tiêt
PhieuXuatKhoService.UpsertPhieuXuatKhoChiTiet = async (data) => {
  return await axiosInstance.put(
    `${PHIEUXUAT_URL}/upsert-phieuxuathang-chitiet`,
    data
  );
};

PhieuXuatKhoService.GetPhieuXuatKhoChiTiet = async (data) => {
  return await axiosInstance.get(
    `${PHIEUXUAT_URL}/get-phieuxuathang-chitiet?Id=${data}`
  );
};

PhieuXuatKhoService.GetTonHangHoa = async (data) => {
  return await axiosInstance.post(`${PHIEUXUAT_URL}/get-tonhanghoa`, data);
};

export default PhieuXuatKhoService;
