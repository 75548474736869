import axiosInstance from "auth/FetchInterceptor";

import { BAOCAO_URL } from "constants/ApiConstant";

const BaoCaoThongKeService = {};

BaoCaoThongKeService.GetBaoCaoTonKho = async (data) => {
  return await axiosInstance.post(`${BAOCAO_URL}/get-baocaotonkho`, data);
};
BaoCaoThongKeService.GetBaoCaoCongNo = async (data) => {
  return await axiosInstance.post(`${BAOCAO_URL}/get-baocaocongno`, data);
};
BaoCaoThongKeService.GetBaoCaoDoanhThu = async (data) => {
  return await axiosInstance.post(`${BAOCAO_URL}/get-baocaodoanhthu`, data);
};
BaoCaoThongKeService.GetBaoCaoThuChi = async (data) => {
  return await axiosInstance.post(`${BAOCAO_URL}/get-baocaothuchi`, data);
};
BaoCaoThongKeService.GetBaoCaoSoNhapXuat = async (data) => {
  return await axiosInstance.post(`${BAOCAO_URL}/get-baocaosonhapxuat`, data);
};
BaoCaoThongKeService.GetDashboardDoanhThu = async (data) => {
  return await axiosInstance.post(`${BAOCAO_URL}/get-dashboard-doanhthu`, data);
};
BaoCaoThongKeService.GetDashboardTongHop = async (data) => {
  return await axiosInstance.post(`${BAOCAO_URL}/get-dashboard-tonghop`, data);
};
BaoCaoThongKeService.GetDashboardTopBanChay = async (data) => {
  return await axiosInstance.post(
    `${BAOCAO_URL}/get-dashboard-topbanchay`,
    data
  );
};
BaoCaoThongKeService.GetDashboardDonHangMoi = async (data) => {
  return await axiosInstance.post(
    `${BAOCAO_URL}/get-dashboard-donhangmoi`,
    data
  );
};

export default BaoCaoThongKeService;
